// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-doc-submission-jsx": () => import("./../../../src/pages/doc-submission.jsx" /* webpackChunkName: "component---src-pages-doc-submission-jsx" */),
  "component---src-pages-escrow-jsx": () => import("./../../../src/pages/escrow.jsx" /* webpackChunkName: "component---src-pages-escrow-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-registry-jsx": () => import("./../../../src/pages/registry.jsx" /* webpackChunkName: "component---src-pages-registry-jsx" */),
  "component---src-pages-title-search-jsx": () => import("./../../../src/pages/title-search.jsx" /* webpackChunkName: "component---src-pages-title-search-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

